<template>
  <el-main>
    <div class="addBoxBig">
      <el-form :model="ruleForm" status-icon :rules="rulesGroup" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <div class="areaTitle">基础信息</div>
        <el-form-item label="任务名称:" prop="name">
          <el-input type="text" v-model="ruleForm.name" placeholder="请输入"></el-input>
          <div class="tips">活码名称将展示在用户扫码后的页面</div>
        </el-form-item>
        <el-form-item v-if="type && type === 'group'" label="群发范围:">
          <el-radio-group v-model="ruleForm.type">
            <el-radio :label="1">全部客户群</el-radio>
            <el-radio :label="2">按群主选择客户群</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="(type && type === 'group' && ruleForm.type === 2) || type !== 'group'" :label="type === 'group' ? '选择群主' : '选择群发成员'">
          <span v-if="groupLeaderList.length > 0">已经选择 {{ groupLeaderList[0].name }} 等{{ groupLeaderList.length }}名成员</span>
          <el-button v-if="type == 'group'" type="text" @click="startChoose">
            {{ groupLeaderList.length > 0 ? '修改群主' : '选择群主' }}
          </el-button>
          <el-button v-else type="text" @click="startChoose">
            {{ groupLeaderList.length > 0 ? '修改成员' : '选择成员' }}
          </el-button>
          <div class="error" v-if="groupLeaderList.length === 0">
            {{ type === 'group' ? '请添加至少一位群主' : '请添加至少一位成员' }}
          </div>
        </el-form-item>
        <el-form-item label="选择客户" v-if="type === 'single'">
          <el-radio-group v-model="ruleForm.type" @change="screenChane">
            <el-radio :label="1">全部客户</el-radio>
            <el-radio :label="2">按条件筛选</el-radio>
          </el-radio-group>
          <div class="screenBox" v-if="type === 'single' && ruleForm.type === 2">
            <div class="screenItem">
              <div class="screenTitle">客户性别：</div>
              <div class="screenContent">
                <el-radio-group v-model="screenInfo.gender">
                  <el-radio :label="-1">全部</el-radio>
                  <el-radio :label="0">未知</el-radio>
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="screenItem">
              <div class="screenTitle">添加日期：</div>
              <div class="screenContent">
                <el-date-picker v-model="screenInfo.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </div>
            <div class="screenItem">
              <div class="screenTitle">标 签 ：</div>
              <div class="screenContent">
                <div class="tagBox">
                  <div :class="['tagItem', item.isSelect ? 'tagSelect' : '']" @click="tagClick(item)" v-for="(item, index) in tagList" :key="index">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="type === 'single'" class="">满足条件的客户数量：{{ ruleForm.external_userid.length }}</div>
        </el-form-item>
        <div class="areaTitle">群发内容</div>
        <el-form-item label="消息内容:" prop="content">
          <el-input type="textarea" :rows="4" placeholder="请输入消息内容" v-model="ruleForm.content" maxlength="600" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="添加附件:">
          <div class="tips">1.选中附件类型 2.点击添加附件按钮</div>
          <EnclosureComponents :data="ruleForm.attachments" :nowUpdateItem="nowUpdateItem" :nowAddEnclosure="nowAddEnclosure" :nowUpdateIndex="nowUpdateIndex"></EnclosureComponents>
          <div class="error">{{ error.attachments }}</div>
        </el-form-item>
        <el-form-item label="群发类型:" prop="send_type">
          <el-radio-group v-model="ruleForm.send_type">
            <el-radio :label="1">立即发送</el-radio>
            <el-radio :label="2">定时发送</el-radio>
          </el-radio-group>
          <br />
          <el-date-picker
            v-show="ruleForm.send_type === 2"
            v-model="ruleForm.send_time"
            type="datetime"
            placeholder="定时发送时间"
            :picker-options="dateOptions"
            @change="changeSendTime"
          ></el-date-picker>
          <div class="error" v-show="ruleForm.send_type === 2 && !ruleForm.send_time">请添加定时发送时间</div>
        </el-form-item>
      </el-form>
      <ShowPhone class="showPhone" :data="ruleForm.attachments"></ShowPhone>
    </div>
    <Preservation @preservation="preservation"></Preservation>
    <!-- 群发到群时选择群主的弹框 -->
    <el-dialog title="选择群主" :visible.sync="choosGroupLeader" width="40%" @close="choosGroupLeaderClosed">
      <div class="groupLeaderBox">
        <No v-if="!departmentList"></No>
        <div>
          <div class="dialogTitle">选择成员:</div>
          <GroupLeaderTree :data="departmentList" @getResult="getGroupLeaderList" :first="true"></GroupLeaderTree>
        </div>
        <div class="rightBox">
          <div class="dialogTitle">已选中的成员:</div>
          <No class="No" v-if="copyGroupLeaderList.length === 0"></No>
          <div class="chooseItem" v-for="(item, index) in copyGroupLeaderList" :key="index">
            {{ item.name }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleChooseMem">取 消</el-button>
        <el-button type="primary" @click="sureChooseMem">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { bus } from '@/util/bus';
import GroupLeaderTree from '@/views/user/enterpriseWechat/components/groupLeaderTree';
import No from '../../../components/no.vue';
import EnclosureComponents from './components/enclosureComponents.vue';
import ShowPhone from './components/phoneShow.vue';
import Preservation from '@/components/preservation';
import { checkAttachments } from '@/util/util.js';
import _ from 'lodash';
export default {
  components: {
    GroupLeaderTree,
    No,
    EnclosureComponents,
    ShowPhone,
    Preservation,
  },
  created() {
    this.type = this.$route.path === '/user/messageTeam/add' ? 'group' : 'single';
    if (this.type === 'single') {
      this.getTagList();
    }
  },
  mounted() {
    bus.$on('changeNowUpdate', (item, index) => {
      this.nowUpdateItem = item;
      this.nowUpdateIndex = index;
      this.nowAddEnclosure = item.msgtype;
    });
    bus.$on('deleteThisFj', index => {
      this.ruleForm.attachments.splice(index, 1);
      this.nowUpdateItem = null;
      this.nowUpdateIndex = null;
      this.nowAddEnclosure = 'image';
    });
  },
  beforeDestroy() {
    bus.$off(['changeNowUpdate', 'deleteThisFj']);
  },
  data() {
    return {
      ruleForm: {
        name: '',
        type: 1,
        external_userid: [],
        content: '',
        attachments: [],
        send_type: 1,
        send_time: null,
      },
      rulesGroup: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        content: [{ required: true, message: '请输入消息内容', trigger: 'blur' }],
      },
      type: '',
      choosGroupLeader: false,
      groupLeaderList: [],
      copyGroupLeaderList: [],
      departmentList: [],
      // 当前显示的附件
      nowUpdateItem: null,
      nowUpdateIndex: null,
      // 当前的单选按钮选中值
      nowAddEnclosure: 'image',
      error: {
        attachments: '',
      },
      dateOptions: {
        disabledDate(val) {
          return val.getTime() < Date.now() - 8.64e7;
        },
      },
      tagList: [],
      screenInfo: {
        gender: -1,
        time: [],
        tag_id: [],
      },
    };
  },
  watch: {
    screenInfo: {
      handler() {
        this.getSatisfyNum();
      },
      deep: true,
    },
  },
  methods: {
    screenChane() {
      if (this.ruleForm.type === 1) {
        this.screenInfo = {
          gender: -1,
          time: [],
          tag_id: [],
        };
        this.tagList.map(item => {
          this.$set(item, 'isSelect', false);
        });
      }
      this.getSatisfyNum();
    },
    // 群发到客户时 获取满足条件的客户数
    getSatisfyNum() {
      if (this.groupLeaderList.length === 0) {
        this.$message.warning('请先选择群发成员');
        return;
      }
      let obj = {
        user_id: [],
        gender: this.screenInfo.gender,
      };
      this.groupLeaderList.map(item => {
        obj.user_id.push(item.user_id);
      });
      if (this.screenInfo.time && this.screenInfo.time.length === 2) {
        obj.start_time = Math.floor(this.screenInfo.time[0].getTime()) / 1000;
        obj.end_time = Math.floor(this.screenInfo.time[1].getTime()) / 1000;
        if (obj.start_time === obj.end_time) {
          obj.end_time += 60 * 60 * 24 - 1;
        }
      }

      if (this.screenInfo.tag_id.length !== 0) {
        obj.tag_id = this.screenInfo.tag_id;
      }
      this.$axios.post(this.$api.user.customerSelect, obj).then(res => {
        if (res.code === 0) {
          this.ruleForm.external_userid = res.result.external_userid;
        }
      });
    },
    // 选中/取消 标签选中状态
    tagClick(item) {
      item.isSelect = !item.isSelect;
      if (item.isSelect) {
        this.screenInfo.tag_id.push(item.tag_id);
      } else {
        let spliceIndex = this.screenInfo.tag_id.findIndex(item1 => item1 === item.tag_id);
        this.screenInfo.tag_id.splice(spliceIndex, 1);
      }
    },
    // 获取用户标签列表
    getTagList() {
      this.$axios.post(this.$api.user.tagList).then(res => {
        if (res.code == 0) {
          this.tagList = res.result;
          this.tagList.map(item => {
            this.$set(item, 'isSelect', false);
          });
        }
      });
    },
    // 如果定时的时间小于现在的时间 默认更改为五分钟后
    changeSendTime() {
      let chooseTime = this.ruleForm.send_time.getTime();
      let nowTime = Date.now();
      if (chooseTime < nowTime) {
        this.ruleForm.send_time = nowTime + 5 * 60 * 1000;
      }
    },
    // 开启选择群主弹框
    startChoose() {
      this.getDepartment();
      this.choosGroupLeader = true;
    },
    // 当选择群主弹框关闭后
    choosGroupLeaderClosed() {
      this.copyGroupLeaderList = _.cloneDeep(this.groupLeaderList);
    },
    // 取消选择群主
    cancleChooseMem() {
      this.choosGroupLeader = false;
      this.copyGroupLeaderList = _.cloneDeep(this.groupLeaderList);
    },
    // 确认选择群主
    sureChooseMem() {
      this.choosGroupLeader = false;
      this.groupLeaderList = _.cloneDeep(this.copyGroupLeaderList);
      this.getSatisfyNum();
    },
    // 获取到部门员工数据
    getDepartment() {
      this.$axios.post(this.$api.user.departmentList).then(res => {
        if (res.code === 0) {
          this.recursionFn(res.result, item => {
            item.isCheck = false;
            item.user_list.map(item1 => {
              let findI = this.groupLeaderList.findIndex(item2 => item2.user_id === item1.user_id);
              if (findI === -1) {
                item1.isCheck = false;
              } else {
                item1.isCheck = true;
              }
            });
          });
          this.departmentList = res.result;
        }
      });
    },
    // 处理递归函数
    recursionFn(arr, callback = () => {}) {
      arr.map(item => {
        callback(item);
        if (item._child && item._child.length > 0) {
          this.recursionFn(item._child, callback);
        }
      });
    },
    // 获取到最后选择的群主
    getGroupLeaderList(type, list) {
      if (type === 'add') {
        for (let i = list.length - 1; i >= 0; i--) {
          let resIndex = this.copyGroupLeaderList.findIndex(item1 => list[i].user_id === item1.user_id);
          if (resIndex === -1) {
            this.copyGroupLeaderList.push(list[i]);
          }
        }
      } else {
        for (let i = this.copyGroupLeaderList.length - 1; i >= 0; i--) {
          let findindex = list.findIndex(item1 => this.copyGroupLeaderList[i].user_id === item1.user_id);
          if (findindex !== -1) {
            this.copyGroupLeaderList.splice(i, 1);
          }
        }
      }
    },
    // 保存这条推送消息
    preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.ruleForm.type === 2 && !this.groupLeaderList.length) {
            this.$message.warning('请添加群主');
            return;
          }
          let { attachmentsFlag, errorTxt, errIndex } = checkAttachments(this.ruleForm.attachments);
          if (!attachmentsFlag) {
            this.error.attachments = errorTxt;
            this.nowAddEnclosure = this.ruleForm.attachments[errIndex].msgtype;
            this.nowUpdateIndex = errIndex;
            this.nowUpdateItem = this.ruleForm.attachments[errIndex];
            return;
          } else {
            this.error.attachments = '';
          }
          if (this.ruleForm.send_type === 2 && !this.ruleForm.send_time) {
            this.$message.warning('请添加定时发送时间');
            return;
          }
          let obj = JSON.parse(JSON.stringify(this.ruleForm));
          let nowRouter = this.$route.path;
          // 判断是群发到群还是群发到客户
          let successToPath = '';
          if (nowRouter === '/user/messageTeam/add') {
            obj.chat_type = 'group';
            // 判断是不是指定群主发送
            successToPath = '/user/enterpriseWechat/messageGroup';
            if (obj.type === 2) {
              obj.sender = [];
              this.groupLeaderList.map(item => {
                obj.sender.push(item.user_id);
              });
            }
          } else if (nowRouter === '/user/messagePerson/add') {
            obj.chat_type = 'single';
            successToPath = '/user/enterpriseWechat/messagePerson';
            delete obj.type;
            delete obj.sender;
          }

          // 判断是否发送附件
          if (obj.attachments.length === 0) {
            obj.is_attachments = 0;
          } else {
            obj.is_attachments = 1;
          }
          obj.send_time = obj.send_time / 1000;
          if (obj.send_type === 1) {
            delete obj.send_time;
          }
          this.$axios.post(this.$api.user.addMessage, obj).then(res => {
            if (res.code === 0) {
              this.$message.success('已成功通知成员发送消息');
              this.$router.push(successToPath);
            } else {
              this.$message.success(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.addBoxBig {
  display: flex;
  justify-content: space-between;
  .showPhone {
    flex-shrink: 0;
    margin-right: 100px;
  }
}
.el-main {
  background-color: #fff;
  padding-bottom: 60px;
  .areaTitle {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 20px;
  }
  .tips {
    font-size: 12px;
    font-weight: 400;
    color: #9a9a9f;
    line-height: 12px;
    margin: 3px 0;
  }
  .error {
    font-size: 12px;
    font-weight: 400;
    color: red;
    line-height: 12px;
    margin: 3px 0;
  }
  .el-form {
    width: 600px;

    & /deep/ .el-input__count {
      line-height: 12px;
    }
  }
  .groupLeaderBox {
    display: flex;
    & > div {
      flex: 1;
      height: 400px;
      flex-shrink: 0;
      overflow: hidden;
      overflow-y: auto;
      overflow-x: auto;
    }
    .dialogTitle {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .No {
      height: calc(100% - 26px);
    }
    .rightBox {
      .chooseItem {
        margin: 5px 0;
      }
    }
  }
}
.screenBox {
  width: 440px;
  background: #f4f6fa;
  border-radius: 4px;
  padding: 20px;
  .screenItem {
    display: flex;
    align-items: center;
    margin: 5px 0;
    .screenTitle {
      width: 70px;
      white-space: nowrap;
      text-align: right;
    }
    .screenContent {
      flex: 1;
    }
    .tagBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .el-date-editor {
      width: 100%;
    }
    .tagItem {
      background: #fff;
      border: 1px solid #d2d2d4;
      border-radius: 4px;
      padding: 0 7px;
      width: 60px;
      height: 32px;
      text-align: center;
      line-height: 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 6px;
      cursor: pointer;
      margin: 3px;
    }
    .tagSelect {
      border: 1px solid #2e75f5 !important;
      color: #2e75f5 !important;
    }
  }
}
</style>
